<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="projectId !== null">
          <display-container :label="`Das Projekt ${ projectName } ist auf den Status 'Bau gestartet' gewechselt`">
            <ProjectDetails
              :projectId="projectId"
              :hide-clever-reach-button="true"
              @projectFetched="projectFetched"
            />
          </display-container>
        </div>
        <div class="margin-bottom-2 align-buttons-right">
          <v-btn
            :disabled="!claimed"
            color="primary"
            @click="completeTask"
          >
            Aufgabe schließen
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionCheckNumberBlock',
  components: {
    DisplayContainer: () => import('@/components/elements/DisplayContainer'),
    TaskBlocker: () => import('@/task/TaskBlocker'),
    ProjectDetails: () =>
      import('@/components/constructionProjects/ProjectDetails')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    comment: '',
    projectId: null,
    project: null
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    projectName() {
      return this.project
        ? `${this.project.name} (${this.projectId})`
        : this.projectId;
    }
  },
  created: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    loadTaskData: function (taskId) {
      HTTP.get(`/task/${taskId}/variables`).then(({ status, data }) => {
        if (status !== 200) {
          return this.showMessage(
            `Prozessvariablen konnten nicht geladen werden. HTTP Status ${status}`
          );
        }

        this.projectId = data.projectId.value;
      });
    },
    projectBulk: function (projectId) {
      return HTTP.get(
        `/projectConstructionState/addresses/projectBulk?projectId=${projectId}`
      ).then(({ status, data }) => {
        if (status !== 200) {
          return this.showMessage(
            `Projekt Informationen konnten nicht geladen werden. HTTP Status ${status}`
          );
        }
        return data;
      });
    },
    projectFetched: function (project) {
      this.project = project;
    },
    showMessage: function (message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    completeTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {})
        .then(() => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showNotification(
            'error',
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        );
    }
  }
};
</script>
